define(['app'], function (app) {

  const checkoutAsGuestSection = () => {
    const component = {};

    const _config = {
      checkoutAsGuestButton: '[data-js-element=guest-checkout-button]'
    };
    const _init = (element) => {
      component.element = element;
      component.checkoutAsGuestButton = component.element.querySelector(component.config.checkoutAsGuestButton);
      component.checkoutAsGuestButton.addEventListener('click', component.guestCheckoutButtonTracking);

      return component;
    };

    const _guestCheckoutButtonTracking = () => {
      app.publish('tracking/record', 'Continue as guest', 'Clicked');
    };

    component.config = _config;
    component.init = _init;
    component.guestCheckoutButtonTracking = _guestCheckoutButtonTracking;
    return component;
  };

  return checkoutAsGuestSection;
});

